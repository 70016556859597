.artefin_container{
    width: 100%;
    max-width: 1560px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
}

.artefin_whiteBtn{
  font-size: 15px;
  font-weight: 600;
  border-bottom: solid 2px black;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 7px 33px 7px 0;
  color: black;
  font-weight: 500;
  background-color: white;
  text-align: left;
  transition: all .35s;
  width: fit-content;
  &:hover {
    padding-right: 23px;
    padding-left: 10px;
  }
}

/* MY BIDS PAGE */
.bids-table{
    font-size: 13px;;
}

.bids-table td{
    vertical-align: baseline;
    height: 62px;
}

.btn-primary {
    color: black;
    background-color: white;
    border-color: black;
    border: solid 1px black;
    border-radius: 0;
    font-size: 13px;
    font-weight: 600;
    &:hover{
        background-color: black;
        color: white;
        border: solid 1px black;
    }
}

.btn-secondary {
    color: white;
    background-color: black;
    border-color: black;
    border: solid 1px black;
    border-radius: 0;
    font-size: 13px;
    font-weight: 600;
    &:hover{
        background-color: black;
        color: white;
        border: solid 1px black;
    }
}

.btn-link{
    color: black;
    text-decoration: underline;
    font-size: 13px;
    font-weight: 600;
    &:hover{
        text-decoration: none;
        color: black;
    }
}

.bids-table a{
    color: black;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
        color: black;
    }
}

.bids-table .table th{
    border-top: none;
    border-bottom: solid 2px black;
}

.walletdropdown *{
    color: black !important;
    font-size: 13px !important;
    font-weight: 600;
}

.walletdropdown svg{
    fill: #000000;
}

.walletdropdown hr{
    border-color: #000000;
}

.walletdropdown > div:nth-child(2){
    background-color: white;
    border-radius: 0;
    border: solid 1px black;
}

.walletdropdown > div:nth-child(1){
    background-color: white;
    border-radius: 0;
    border: solid 1px black;
}

.walletdropdown > div:nth-child(1) > div:nth-child(1){
    background-color: #ffffff;
    border-color: #000000;
    border-radius: 0;
    border-width: 1px;
}

.walletdropdown > div:nth-child(1) > div:nth-child(1) svg{
    fill: #000000;
}

.nav_right {
    justify-content: flex-end;
}
.p10 {
    padding: 10px;
}
html {
    scroll-behavior: smooth;
}
.padding {
    padding: 0 10%;
    @media screen and (max-width: 768px) {
        padding: 0 20px;
    }
}
.between {
    justify-content: space-between;
}
.row {
    display: flex;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
}
.col {
    display: flex;
    flex-direction: column;
}
.flex-center {
    width: 100%;
    display: flex;
    justify-self: center;
    align-items: center;
}
::-webkit-scrollbar-thumb {
    background: #262825 !important;
}
.center {
    justify-content: center;
}
@keyframes hideshow {
    0%   {opacity: 0;}
    25%  {opacity: 0.5;}
    50%  {opacity: 1;}
    75%  {opacity: 0.5;}
    100% {opacity: 0;}
}
  

.topBid{
    font-size: 24px;
    color: #34C88A;
    text-align: center;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 20px;
}

.bidInpError{
    font-size: 12px;
    font-weight: 700;
    color: red;
    margin-top: 5px;
}

.cookiesBtn{
    background-color: white !important;
    color: #000000 !important;
    border: solid 1px black !important;
    padding: 6px 20px !important;
}

.underLink{
    font-weight: bold;
    text-decoration: underline;
}